import { getEaster } from "easter-date";

const FULL_DAY = 86_400_000;

export function isEaster() {
  const date = new Date();
  const easter = getEaster(date.getFullYear());
  return (
    easter.getTime() - 14 * FULL_DAY < date.getTime() &&
    date.getTime() <= easter.getTime() + FULL_DAY
  );
}
