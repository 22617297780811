import { getEaster } from "easter-date";

const FULL_DAY = 86_400_000;

export function isCarnival() {
  const date = new Date();
  const easter = getEaster(date.getFullYear());
  return (
    easter.getTime() - 63 * FULL_DAY < date.getTime() &&
    date.getTime() <= easter.getTime() - 42 * FULL_DAY
  );
}
